import Group from "@/tpo/Group";
import { PROGRESS_STATUS_ORDERED, STATUS_VARIANTS } from "@/tpo/shop/constants";

import StatusBadge from "./organisations/orders/ui/StatusBadge";

export default function OrderTestItemStatusBadges({
  progressStatusBadgeProps,
  orderTestItem,
  badgeProps,
  ...props
}) {
  let registeredStatus = null;
  let registeredVariant = null;

  let progressStatus = null;
  let progressVariant = null;

  if (!orderTestItem || !orderTestItem.id) {
    return null;
  }
  if (orderTestItem.registered) {
    registeredStatus = "Registered";
    registeredVariant = "success";
  } else {
    registeredStatus = "To Register";
    registeredVariant = "failure";
  }

  if (orderTestItem.derivedProgressStatus) {
    progressStatus = orderTestItem.derivedProgressStatus;
    progressVariant = STATUS_VARIANTS[orderTestItem.derivedProgressStatus];
  } else {
    progressStatus = PROGRESS_STATUS_ORDERED;
    progressVariant = STATUS_VARIANTS[PROGRESS_STATUS_ORDERED];
  }

  if (registeredStatus === null && progressStatus === null) {
    return null;
  }

  return (
    <Group gap={10} alignItems="center" alignSelf="flex-start" flexWrap="wrap" {...props}>
      {registeredStatus && (
        <StatusBadge
          variant={registeredVariant}
          {...badgeProps}
          data-testid="registeredItemStatusBadge"
        >
          {registeredStatus}
        </StatusBadge>
      )}
      {progressStatus && (
        <StatusBadge
          variant={progressVariant}
          {...badgeProps}
          {...progressStatusBadgeProps}
          data-testid="progressItemStatusBadge"
        >
          {progressStatus}
        </StatusBadge>
      )}
    </Group>
  );
}
