import Box from "@/components/Box";
import Grid from "@/components/Grid";
import { ExternalTextLink, InternalTextLink } from "@/components/Links";
import {
  OMNOS_ABOUT_URL,
  OMNOS_CONTACT_URL,
  OMNOS_HOW_IT_WORKS_URL,
  OMNOS_PLATFORM_URL,
  PARTNERS_PRACTITIONER_URL,
  PRIVACY_URL,
  REGENERUS_ABOUT_URL,
  REGENERUS_CONTACT_URL,
  REGENERUS_PATIENT_PORTAL_URL,
  REGENERUS_PRACTITIONER_PORTAL_URL,
  REGENERUS_PRIVACY_URL,
  REGENERUS_SHIPPING_URL,
  REGENERUS_TERMS_AND_CONDITIONS_URL,
  REGENERUS_TERMS_OF_USE_URL,
  SHIPPING_AND_RETURNS_URL,
  SUPPLEMENTS_URL,
  TERMS_OF_SERVICE_URL,
  TERMS_OF_USE_URL,
  TESTS_URL
} from "@/core/urls";
import usePartnerPageCheck from "@/hooks/partner/usePartnerPageCheck";
import RegenerusLogo from "@/images/RegenerusLogoWithoutDots";
import Logo from "@/images/logo.svg?react";
import { PanelBoxV2 } from "@/tpo/Boxes";
import Stack from "@/tpo/Stack";

import classes from "./Footer.module.css";

const SHOP_TEST_LINK = {
  label: "Tests",
  href: TESTS_URL
};

const SUPPLEMENTS_TEST_LINK = {
  label: "Supplements",
  href: SUPPLEMENTS_URL
};

const TERM_AND_CONDITIONS_LINK = {
  label: "Terms & Conditions",
  href: TERMS_OF_SERVICE_URL
};

const TERMS_OF_USE_LINK = {
  label: "Terms of Use",
  href: TERMS_OF_USE_URL
};

const SHIPPING_POLICY_LINK = {
  label: "Shipping Policy",
  href: SHIPPING_AND_RETURNS_URL
};

const PRIVATE_POLICY_LINK = {
  label: "Privacy Policy",
  href: PRIVACY_URL
};

const partnerLinks = [
  {
    column: "Shop",
    links: [
      SHOP_TEST_LINK,
      SUPPLEMENTS_TEST_LINK,
      {
        label: "European labs",
        href: "https://www.regeneruslabs.me/#",
        external: true
      }
    ]
  },
  {
    column: "About Us",
    links: [
      {
        label: "Our Mission",
        href: REGENERUS_ABOUT_URL,
        external: true
      },
      {
        label: "Meet the team",
        href: REGENERUS_ABOUT_URL,
        external: true
      }
    ]
  },
  {
    column: "Practitioners",
    links: [
      {
        label: "Patient portal",
        href: REGENERUS_PATIENT_PORTAL_URL,
        external: true
      },
      {
        label: "Practitioner platform",
        href: REGENERUS_PRACTITIONER_PORTAL_URL,
        external: true
      },
      {
        label: "Practitioner application",
        href: PARTNERS_PRACTITIONER_URL,
        external: false
      }
    ]
  },
  {
    column: "Policies",
    links: [
      {
        label: "Terms & Conditions",
        href: "/partners" + REGENERUS_TERMS_AND_CONDITIONS_URL
      },
      {
        label: "Terms of Use",
        href: "/partners" + REGENERUS_TERMS_OF_USE_URL
      },
      {
        label: "Shipping Policy",
        href: "/partners" + REGENERUS_SHIPPING_URL
      },
      {
        label: "Privacy Policy",
        href: "/partners" + REGENERUS_PRIVACY_URL
      }
    ]
  },
  {
    column: "Help and support",
    links: [
      {
        label: "Contact us",
        href: REGENERUS_CONTACT_URL,
        external: true
      }
    ]
  }
];

const userLinks = [
  {
    column: "Shop",
    links: [SHOP_TEST_LINK, SUPPLEMENTS_TEST_LINK]
  },
  {
    column: "About Us",
    links: [
      {
        label: "Our Mission",
        href: OMNOS_ABOUT_URL,
        external: true
      },
      {
        label: "Meet the team",
        href: OMNOS_ABOUT_URL,
        external: true
      }
    ]
  },
  {
    column: "Platform",
    links: [
      {
        label: "Patient Portal",
        href: OMNOS_PLATFORM_URL,
        external: true
      },
      {
        label: "Practitioner platform",
        href: OMNOS_HOW_IT_WORKS_URL,
        external: true
      },
      {
        label: "Practitioner application",
        href: PARTNERS_PRACTITIONER_URL,
        external: false
      }
    ]
  },
  {
    column: "Policies",
    links: [TERM_AND_CONDITIONS_LINK, TERMS_OF_USE_LINK, SHIPPING_POLICY_LINK, PRIVATE_POLICY_LINK]
  },
  {
    column: "Help and support",
    links: [
      {
        label: "Contact us",
        href: OMNOS_CONTACT_URL,
        external: true
      }
    ]
  }
];

function FooterTemplate({ logo, links }) {
  return (
    <Grid
      gridTemplateColumns={[
        "repeat( auto-fit, minmax(120px, 1fr) )",
        "repeat( auto-fit, minmax(120px, 1fr) )",
        "260px repeat( auto-fit, minmax(120px, 1fr) )"
      ]}
      gridRowGap={[50, 80, 80]}
      gridColumnGap={[50, 80, 80]}
    >
      <Box gridColumnStart={["1", "unset"]} gridColumnEnd={["3", "unset"]}>
        {logo}
      </Box>
      {links.map((col, idx) => (
        <Stack key={idx} gap={40}>
          <Box color="white" fontFamily="gilroyBold" fontSize={16}>
            {col.column}
          </Box>
          <Stack gap={20}>
            {col.links.map(link =>
              link.external ? (
                <ExternalTextLink
                  color="white"
                  key={link.label}
                  href={link.href}
                  fontFamily="gilroyMedium"
                  fontSize={16}
                  underline={false}
                  className={classes.link}
                >
                  {link.label}
                </ExternalTextLink>
              ) : (
                <InternalTextLink
                  color="white"
                  key={link.label}
                  href={link.href}
                  fontFamily="gilroyMedium"
                  fontSize={16}
                  underline={false}
                  className={classes.link}
                >
                  {link.label}
                </InternalTextLink>
              )
            )}
          </Stack>
        </Stack>
      ))}
    </Grid>
  );
}

export default function Footer() {
  const isPartnerPage = usePartnerPageCheck({
    excludeApplicationPages: false
  });

  return (
    <PanelBoxV2
      maxWidth={1538}
      outer={{
        py: 40,
        bg: "dark",
        px: [20, 20, 95],
        "data-component-name": "Footer"
      }}
    >
      <FooterTemplate
        logo={isPartnerPage ? <RegenerusLogo fill="white" /> : <Logo height={50} />}
        links={isPartnerPage ? partnerLinks : userLinks}
      />
    </PanelBoxV2>
  );
}
