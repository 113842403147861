import { ExternalTextLink } from "@/components/Links";
import OrderTestItemStatusBadges from "@/components/OrderTestItemStatusBadges";
import { getPartnerTestResultsUrl, getPatientTestUrl } from "@/core/urls";
import {
  PROGRESS_STATUS_INTERIM_RESULTS_RELEASED,
  PROGRESS_STATUS_PARTIAL_RESULTS_RELEASED,
  PROGRESS_STATUS_RESULTS_RELEASED
} from "@/tpo/shop/constants";

export default function CmpOrderTestItemStatusBadges({
  order,
  orderTestItem,
  badgeProps,
  ...props
}) {
  let progressStatusBadgeProps = {};

  if (
    [
      PROGRESS_STATUS_INTERIM_RESULTS_RELEASED,
      PROGRESS_STATUS_RESULTS_RELEASED,
      PROGRESS_STATUS_PARTIAL_RESULTS_RELEASED
    ].includes(orderTestItem.derivedProgressStatus)
  ) {
    progressStatusBadgeProps = {
      ...progressStatusBadgeProps,
      as: ExternalTextLink,
      href: getPatientTestUrl(order?.user?.id, orderTestItem?.userTestSet?.[0]?.id),
      underline: false,
      onClick: e => {
        e.stopPropagation();
      }
    };
  }

  return (
    <OrderTestItemStatusBadges
      progressStatusBadgeProps={progressStatusBadgeProps}
      orderTestItem={orderTestItem}
      badgeProps={badgeProps}
      {...props}
    />
  );
}
