import { useState } from "react";

import Box from "@/components/Box";
import Grid from "@/components/Grid";
import { theme } from "@/core/theme";
import Center from "@/tpo/Center";
import SegmentedControl from "@/tpo/SegmentedControl";
import Spacer from "@/tpo/Spacer";
import ButtonV2 from "@/v2/Buttons";

import ChevronComponent from "../Chevron";
import Group from "../Group";

export default function BiomarkersAndSymptomsList({ biomarkersTested, relatedSymptoms }) {
  const [biomarkerSymptomsSwitch, setBiomarkerSymptomsSwitch] = useState("biomarkers");
  const [expandedBioMarkers, setExpandedBioMarkers] = useState(false);
  const [expandedSymptoms, setExpandedSymptoms] = useState(false);

  const biomarkersTestedElement = (
    <>
      <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
        Tested Biomarkers
      </Box>
      <Spacer py={[20]} />
      <Grid
        as="ul"
        gridColumnGap={40}
        gridTemplateColumns={["auto", "auto", "auto auto auto"]}
        style={{
          listStylePosition: "inside",
          listStyleType: "disc",
          textIndent: "-13px",
          paddingLeft: "13px"
        }}
      >
        {biomarkersTested.slice(0, expandedBioMarkers ? undefined : 30).map(bm => (
          <Box as="li" key={bm}>
            {bm}
          </Box>
        ))}
      </Grid>
      {biomarkersTested.length > 30 && (
        <Group justifyContent="flex-end">
          <ButtonV2
            onClick={() => setExpandedBioMarkers(!expandedBioMarkers)}
            variant="transparent"
            sx={{
              color: "dark"
            }}
            rightIcon={
              <ChevronComponent
                direction={expandedBioMarkers ? "top" : "bottom"}
                fill={theme.colors.dark}
              />
            }
          >
            see all
          </ButtonV2>
        </Group>
      )}
    </>
  );

  const relatedSymptomsElement = (
    <>
      <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
        Symptoms
      </Box>
      <Spacer py={[20]} />
      <Grid
        as="ul"
        gridColumnGap={40}
        gridTemplateColumns={["auto", "auto", "auto auto auto"]}
        style={{
          listStylePosition: "inside",
          listStyleType: "disc",
          textIndent: "-13px",
          paddingLeft: "13px"
        }}
      >
        {relatedSymptoms.slice(0, expandedSymptoms ? undefined : 30).map(rs => (
          <Box as="li" key={rs}>
            {rs}
          </Box>
        ))}
      </Grid>
      {relatedSymptoms.length > 30 && (
        <Group justifyContent="flex-end">
          <ButtonV2
            onClick={() => setExpandedSymptoms(!expandedSymptoms)}
            variant="transparent"
            sx={{
              color: "dark"
            }}
            rightIcon={
              <ChevronComponent
                direction={expandedSymptoms ? "top" : "bottom"}
                fill={theme.colors.dark}
              />
            }
          >
            see all
          </ButtonV2>
        </Group>
      )}
    </>
  );

  if (biomarkersTested?.length && relatedSymptoms?.length) {
    return (
      <>
        <Center>
          <SegmentedControl
            data={[
              {
                label: "biomarkers",
                value: "biomarkers"
              },
              {
                label: "symptoms",
                value: "symptoms"
              }
            ]}
            onChange={setBiomarkerSymptomsSwitch}
            value={biomarkerSymptomsSwitch}
            height={40}
          />
        </Center>
        <Spacer py={[15, 15, 30]} />
        {biomarkerSymptomsSwitch === "biomarkers"
          ? biomarkersTestedElement
          : relatedSymptomsElement}
      </>
    );
  } else if (biomarkersTested?.length) {
    return biomarkersTestedElement;
  } else if (relatedSymptoms?.length) {
    return relatedSymptomsElement;
  }

  return null;
}
