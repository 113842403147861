import Box from "@/components/Box";
import { useOrganisationContext } from "@/contexts/organisations/OrganisationContext";
import InfinityIcon from "@/images/InfinityIcon";
import { PanelBoxV2 } from "@/tpo/Boxes";
import Group from "@/tpo/Group";
import Stack from "@/tpo/Stack";
import ListPageTemplate from "@/tpo/shop/ListPageTemplate";
import copyTextToClipboard from "@/utils/copyTextToClipboard";
import ButtonV2 from "@/v2/Buttons";
import { gql, useQuery } from "@apollo/client";
import { useTheme } from "styled-components";

import OrganisationTabs from "../OrganisationTabs";

const TRADE_DISCOUNT_QUERY = gql`
  query TradeDiscountQuery($organisation: ID!) {
    tradeDiscounts(organisation: $organisation) {
      id
      displayName
      code
      value
      available
      used
      validTo
      supportedTestNames
      description
    }
  }
`;

function DiscountCard({
  displayName,
  code,
  value,
  available,
  used,
  expires,
  supportedTestNames,
  detail
}) {
  return (
    <Stack borderRadius={20} bg="white" gap={40} p={40}>
      <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight={["32px", "32px", "42px"]}>
        {displayName}
      </Box>
      <Group justifyContent="space-between" flexWrap="wrap">
        <Stack width={["100%", "100%", "50%"]}>
          <Box
            fontFamily="gilroyBold"
            fontSize={[18, 18, 24]}
            lineHeight={["28px", "28px", "36px"]}
          >
            {code}
          </Box>
          <ButtonV2
            color="green"
            size="sm"
            onClick={() => {
              copyTextToClipboard(code);
            }}
            mr="auto"
          >
            copy code
          </ButtonV2>
        </Stack>
        <Group
          width={["100%", "100%", "50%"]}
          justifyContent="space-between"
          gap="10px 24px"
          mt={[20, 20, 0]}
          flexWrap="wrap"
        >
          <Stack gap={10}>
            <Box fontFamily="gilroyRegular" fontSize={14} lineHeight="24px">
              Discount value
            </Box>
            <Box fontFamily="gilroyBold" fontSize={24} lineHeight="36px">
              {value}
            </Box>
          </Stack>
          <Stack gap={10}>
            <Box fontFamily="gilroyRegular" fontSize={14} lineHeight="24px">
              Available
            </Box>
            <Box fontFamily="gilroyBold" fontSize={24} lineHeight="36px">
              {available}
            </Box>
          </Stack>
          <Stack gap={10}>
            <Box fontFamily="gilroyRegular" fontSize={14} lineHeight="24px">
              Used
            </Box>
            <Box fontFamily="gilroyBold" fontSize={24} lineHeight="36px">
              {used}
            </Box>
          </Stack>
          <Stack gap={10}>
            <Box fontFamily="gilroyRegular" fontSize={14} lineHeight="24px">
              Expires
            </Box>
            <Box fontFamily="gilroyMedium" fontSize={18} lineHeight="28px">
              {expires}
            </Box>
          </Stack>
        </Group>
      </Group>
      <Group flexWrap="wrap" gap={[40, 40, 40]}>
        {!!supportedTestNames?.length && (
          <Group
            width={["100%", "100%", "calc(50% - 20px)"]}
            pr={[0, 0, 20]}
            borderRight={[null, null, detail ? "1px solid black" : null]}
          >
            <Stack gap={20}>
              <Box
                fontFamily="gilroyBold"
                fontSize={12}
                lineHeight="70%"
                letterSpacing="2.8px"
                textTransform="uppercase"
              >
                Supported tests
              </Box>
              <Group flexWrap="wrap" gap="10px 21px">
                {supportedTestNames.map(stn => (
                  <Box fontFamily="gilroyMedium" fontSize={14} lineHeight="24px" key={stn}>
                    {stn}
                  </Box>
                ))}
              </Group>
            </Stack>
          </Group>
        )}
        {detail && (
          <Group width={["100%", "100%", "calc(50% - 20px)"]}>
            <Stack gap={20}>
              <Box
                fontFamily="gilroyBold"
                fontSize={12}
                lineHeight="70%"
                letterSpacing="2.8px"
                textTransform="uppercase"
              >
                discount detail
              </Box>
              <Box fontFamily="gilroyMedium" fontSize={14} lineHeight="24px">
                {detail}
              </Box>
            </Stack>
          </Group>
        )}
      </Group>
    </Stack>
  );
}

export default function DiscountListPage() {
  const theme = useTheme();
  const { organisation } = useOrganisationContext();

  const { data, loading } = useQuery(TRADE_DISCOUNT_QUERY, {
    variables: {
      organisation: organisation?.id
    }
  });

  const tradeDiscounts = data?.tradeDiscounts || [];

  return (
    <ListPageTemplate
      bg="haze"
      urlMap={{
        supplements: "/partners/dashboard/shop/supplements",
        tests: "/partners/dashboard/shop/tests",
        discounts: "/partners/dashboard/shop/discounts"
      }}
      header={<OrganisationTabs selectedTab="shop" />}
      tab="discounts"
      title="Available discounts"
      subtitle={
        tradeDiscounts?.length
          ? "The below discounts are available for you and your organisation to utilise."
          : !loading
          ? "No discounts currently active."
          : null
      }
      pb={[30, 30, 60]}
    >
      <PanelBoxV2
        maxWidth={1280}
        outer={{
          bg: "haze",
          pb: theme.spacing.section.pb,
          px: [20, 20, "5.5vw"]
        }}
        stacked
        gap={20}
      >
        {tradeDiscounts.map(td => (
          <DiscountCard
            key={td.id}
            detail={td.description}
            displayName={td.displayName}
            code={td.code}
            value={td.value}
            available={td.available === null ? <InfinityIcon /> : td.available}
            used={td.used}
            expires={
              td.validTo ? (
                new Date(td.validTo).toLocaleDateString("en-GB", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric"
                })
              ) : (
                <InfinityIcon />
              )
            }
            supportedTestNames={td.supportedTestNames}
          />
        ))}
      </PanelBoxV2>
    </ListPageTemplate>
  );
}
