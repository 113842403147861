import React from "react";

import useDocTitle from "@/hooks/use-doc-title";

import IFramePage from "./IFramePage";

function RegenerusShippingPolicyPage() {
  useDocTitle("Shipping Policy");
  return (
    <IFramePage
      withLogo={false}
      bg="partners"
      title="Shipping Policy"
      src="/regenerus-shipping-policy.html"
    />
  );
}

export default RegenerusShippingPolicyPage;
