import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import AcceptOrganisationInvite from "@/components/AcceptOrganisationInvite";
import AcceptPartnerLinkInvite from "@/components/AcceptPartnerLinkInvite";
import AuthRoute from "@/components/AuthRoute";
import Error404Page from "@/components/Error404Page";
import Page from "@/components/Page";
import PartnerSignIn from "@/components/PartnerSignIn";
import PartnersMarketingRedirect from "@/components/PartnersMarketingRedirect";
import PartnersPage from "@/components/PartnersPage";
import PrivacyPage from "@/components/PrivacyPage";
import RegenerusPrivacyPolicyPage from "@/components/RegenerusPrivacyPolicy";
import RegenerusShippingPolicyPage from "@/components/RegenerusShipping";
import RegenerusTermsAndConditionsPage from "@/components/RegenerusTermsAndConditions";
import RegenerusTermsOfUsePage from "@/components/RegenerusTermsOfUse";
import ShippingAndReturnsPolicyPage from "@/components/ShippingAndReturnsPolicy";
import ResetPassword from "@/components/resetPassword";
import ParamsContext from "@/contexts/ParamsContext";
import { PRACTITIONER } from "@/core/constants";
import {
  PARTNERS_DASHBOARD_URL,
  REGENERUS_PRIVACY_URL,
  REGENERUS_SHIPPING_URL,
  REGENERUS_TERMS_AND_CONDITIONS_URL,
  REGENERUS_TERMS_OF_USE_URL,
  getPatientFoodListUrl,
  getPatientRecommendationUrl,
  getPatientRecommendationsUrl,
  getPatientSupplementUrlUsingSlug,
  getPatientTestBiomarkerUrl,
  getPatientTestResultUrl
} from "@/core/urls";

import DashboardSwitcher from "./DashboardSwitcher";
import LoggedOutRoute from "./LoggedOutRoute";
import Params from "./Params";
import PartnerRoute from "./PartnerRoute";
import { ReadOnlyOrderDetailPage } from "./orders/OrderDetailPage";
import PartnerDashboard from "./partnerDashboard";
import AffiliateSignupRedirect from "./partnerDashboard/affiliate/SignupRedirect";

function Partners() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${match.path}${REGENERUS_TERMS_AND_CONDITIONS_URL}`}
        exact
        component={RegenerusTermsAndConditionsPage}
      />
      <Route
        path={`${match.path}${REGENERUS_TERMS_OF_USE_URL}`}
        exact
        component={RegenerusTermsOfUsePage}
      />
      <Route
        path={`${match.path}${REGENERUS_PRIVACY_URL}`}
        exact
        component={RegenerusPrivacyPolicyPage}
      />
      <Route
        path={`${match.path}${REGENERUS_SHIPPING_URL}`}
        exact
        component={RegenerusShippingPolicyPage}
      />
      <Route exact path={match.path}>
        <PartnersMarketingRedirect />
      </Route>
      <Route from={`${match.path}/affiliate`} exact>
        <AffiliateSignupRedirect />
      </Route>
      <Route path={`${match.path}/practitioner`} exact>
        <PartnersPage tier={PRACTITIONER} />
      </Route>
      <Redirect from={`${match.path}/signin`} to={`${match.path}/login`} exact />
      <LoggedOutRoute path={`${match.path}/login`} exact partners>
        <PartnerSignIn />
      </LoggedOutRoute>
      <LoggedOutRoute path={`${match.path}/password-reset`} partners>
        <ResetPassword partners />
      </LoggedOutRoute>
      <Route
        path={`${match.path}/patient_pay_order_confirmation/:id`}
        component={ReadOnlyOrderDetailPage}
        exact
      />
      <AuthRoute
        path={`${match.path}/invite/:inviteId/accept/:token`}
        component={AcceptOrganisationInvite}
        exact
      />
      <Route
        path={`${match.path}/patient_invite/organisation/:organisation/partner/:partnerLinkCode`}
        component={AcceptPartnerLinkInvite}
        exact
      />
      <PartnerRoute
        renderLoadingTemplate={loadingContent => (
          <Page addNavBarHeight={false} isPartnerPage>
            <DashboardSwitcher bg="partners" isPartnerPage />
            {loadingContent}
          </Page>
        )}
        path={`${match.path}/dashboard`}
      >
        <PartnerDashboard />
      </PartnerRoute>
      <Route path={`${match.path}/:tab`} exact>
        <Redirect to={PARTNERS_DASHBOARD_URL} />
      </Route>
      <Route path={`${match.path}/test-result/:clientId/:userResultId`} exact>
        <Params>
          <ParamsContext.Consumer>
            {({ clientId, userResultId }) => (
              <Redirect to={getPatientTestResultUrl(clientId, userResultId)} />
            )}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Route path={`${match.path}/test-biomarker/:clientId/:userDatapointId`} exact>
        <Params>
          <ParamsContext.Consumer>
            {({ clientId, userDatapointId }) => (
              <Redirect to={getPatientTestBiomarkerUrl(clientId, userDatapointId)} />
            )}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Route path={`${match.path}/:clientId/recommendations`} exact>
        <Params>
          <ParamsContext.Consumer>
            {({ clientId }) => <Redirect to={getPatientRecommendationsUrl(clientId)} />}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Route path={`${match.path}/:clientId/recommendations/:recommendationId`} exact>
        <Params>
          <ParamsContext.Consumer>
            {({ clientId, recommendationId }) => (
              <Redirect to={getPatientRecommendationUrl(clientId, recommendationId)} />
            )}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Route path={`${match.path}/supplements/:clientId/:slug`}>
        <Params>
          <ParamsContext.Consumer>
            {({ clientId, slug }) => (
              <Redirect to={getPatientSupplementUrlUsingSlug(clientId, slug)} />
            )}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Route path={`${match.path}/food-list/:clientId/`}>
        <Params>
          <ParamsContext.Consumer>
            {({ clientId }) => <Redirect to={getPatientFoodListUrl(clientId)} />}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Error404Page />
    </Switch>
  );
}

export default Partners;
