import { useEffect, useRef, useState } from "react";

import Box from "@/components/Box";
import { ExternalTextLink } from "@/components/Links";
import { getAPIBase } from "@/core/config";
import {
  BASKET_PAYEE_PATIENT,
  ORDER_PRODUCT_REORDER,
  ORDER_RESEND_STATUS,
  ORDER_RETEST_STATUS,
  PAYMENT_OBJECT_STATUS_CREATED,
  PAYMENT_OBJECT_STATUS_PAID
} from "@/core/constants";
import Badge from "@/tpo/Badge";
import Group from "@/tpo/Group";
import Stack from "@/tpo/Stack";
import {
  PAYMENT_STATUS_AWAITING_PAYMENT,
  PAYMENT_STATUS_PATIENT_TO_PAY
} from "@/tpo/shop/constants";
import copyTextToClipboard from "@/utils/copyTextToClipboard";
import ButtonV2, { IndicativeButton } from "@/v2/Buttons";

import OrderStatusBadges from "../ui/OrderStatusBadges";
import classes from "./OrganisationOrderSummary.module.css";
import ResendOrderEmailButton from "./ResendOrderEmailButton";

function StripePaymentLink({ url, text, newTab = false }) {
  return (
    <ExternalTextLink
      onClick={e => {
        e.clickWithinOrganisationOrderSummaryComponent = true;
      }}
      href={url}
    >
      {text}
    </ExternalTextLink>
  );
}

function parseStripeDetails(payee, paymentCheckoutSession, paymentInvoice, orderId) {
  if (paymentInvoice?.url) {
    // Paid or not, if there's an invoice URL, show it
    return {
      label: "Payment:",
      value: <StripePaymentLink url={paymentInvoice.url} text="View Invoice" newTab={true} />
    };
  }

  const isPatient = payee === "patient";
  const isPaid = paymentCheckoutSession?.paymentStatus === PAYMENT_OBJECT_STATUS_PAID;

  // Handle paid checkout sessions
  if (isPaid) {
    if (paymentCheckoutSession.invoiceUrl) {
      const text = isPatient ? "View Details" : "View Invoice";
      return {
        label: "Payment:",
        value: (
          <StripePaymentLink url={paymentCheckoutSession.invoiceUrl} text={text} newTab={true} />
        )
      };
    }
    return null;
  }

  // Handle unpaid checkout sessions
  if (paymentCheckoutSession) {
    const text = isPatient ? "Payment Link" : "Pay now";
    return {
      label: "Payment:",
      value: <StripePaymentLink url={`${getAPIBase()}/payments/checkout/${orderId}`} text={text} />
    };
  }

  return null;
}

function CopyPaymentLinkToClipboardButton({ order }) {
  const ref = useRef();
  return (
    <IndicativeButton
      ref={ref}
      defaultColor="green"
      pendingColor="darkgrey"
      successColor="#2ecc71"
      failureColor="error"
      type="button"
      onClick={e => {
        e.copyPaymentLinkToClipboardButton = true;
        new Promise(resolve => {
          copyTextToClipboard(`https://app.regeneruslabs.me/payments/checkout/${order.id}`);
          resolve();
        }).then(() => {
          ref.current.setSuccessful(true);
          ref.current.setPending(false);
        });
      }}
      size="xs"
    >
      copy
    </IndicativeButton>
  );
}

export default function OrganisationOrderSummary({ order }) {
  const stripeDetails = parseStripeDetails(
    order.sourceBasket.payee,
    order.sourceBasket.paymentCheckoutSession,
    order.sourceBasket.paymentInvoice,
    order.id
  );

  let additionalStatus = null;

  if (order.suppliedFromPracticeStock) {
    additionalStatus = "From Stock";
  } else if (order.isStockOrder) {
    additionalStatus = "Stock Order";
  } else if (
    order.status === ORDER_RESEND_STATUS ||
    order.status === ORDER_RETEST_STATUS ||
    order.status === ORDER_PRODUCT_REORDER
  ) {
    additionalStatus = order.status;
  }

  return (
    <Box
      display="flex"
      flexDirection={["column", "column", "row"]}
      justifyContent={["flex-start", "flex-start", "space-between"]}
      py={4}
      gap={20}
    >
      <Stack gap={10}>
        {additionalStatus && (
          <Box alignItems="flex-start" fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
            <Badge bg="black" color="white" ml="auto" size="xs">
              {additionalStatus}
            </Badge>
          </Box>
        )}
        <Group gap={10}>
          <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
            Order No:
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} data-testid="orderDetails:orderNo">
            {order.id}
          </Box>
        </Group>
        {stripeDetails && (
          <Group gap={10}>
            <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]} data-testid="stripeDetails:label">
              {stripeDetails.label}
            </Box>
            <Box
              fontFamily="gilroyMedium"
              fontSize={[14, 14, 16]}
              data-testid="stripeDetails:value"
            >
              {stripeDetails.value}
            </Box>
          </Group>
        )}
        <Group gap={10}>
          <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
            Order Date:
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} data-testid="orderDate">
            {new Date(order.checkoutDate).toLocaleDateString()}
          </Box>
        </Group>
        {order.sourceBasket &&
        order.sourceBasket.paymentInvoice &&
        order.sourceBasket.paymentInvoice.dateDue &&
        order.sourceBasket.paymentInvoice.paymentStatus === PAYMENT_OBJECT_STATUS_CREATED ? (
          <Group gap={10} alignItems="center" flexWrap="wrap">
            <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
              Payment due:
            </Box>
            <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} data-testid="paymentDue">
              {new Date(order.sourceBasket.paymentInvoice.dateDue).toLocaleDateString()}
            </Box>
            {new Date(order.sourceBasket.paymentInvoice.dateDue) > new Date() &&
            order.sourceBasket.paymentInvoice.url ? (
              <ButtonV2
                as="a"
                color="green"
                href={order.sourceBasket.paymentInvoice.url}
                sx={{
                  lineHeight: 1,
                  cursor: "pointer",
                  px: 20,
                  py: 2,
                  fontSize: 12
                }}
                data-testid="payNowButton"
                onClick={e => {
                  e.clickWithinOrganisationOrderSummaryComponent = true;
                }}
                size="xs"
              >
                pay now
              </ButtonV2>
            ) : null}
          </Group>
        ) : null}
        {order?.sourceBasket?.payee === BASKET_PAYEE_PATIENT &&
          order.derivedPaymentStatus === PAYMENT_STATUS_PATIENT_TO_PAY && (
            <Group alignItems="center" gap={10}>
              <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
                Patient pay link:
              </Box>
              <CopyPaymentLinkToClipboardButton order={order} />
            </Group>
          )}
        {order?.sourceBasket?.payee === BASKET_PAYEE_PATIENT &&
          order.derivedPaymentStatus === PAYMENT_STATUS_PATIENT_TO_PAY && (
            <Group alignItems="center" gap={10}>
              <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
                Patient order email:
              </Box>
              <ResendOrderEmailButton order={order} />
            </Group>
          )}
        {!!order.estimatedResultsAvailableDate && (
          <Group gap={10}>
            <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
              Results expected:
            </Box>
            <Box
              fontFamily="gilroyMedium"
              fontSize={[14, 14, 16]}
              data-testid="estimatedResultsAvailableDate"
            >
              {new Date(order.estimatedResultsAvailableDate).toLocaleDateString()}
            </Box>
          </Group>
        )}
        <Group gap={10}>
          <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
            Name:
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} data-testid="orderName">
            {order.user.firstName} {order.user.lastName}
          </Box>
        </Group>
        {!!order.testSummary && (
          <Group gap={10}>
            <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
              Tests:
            </Box>
            <Box
              fontFamily="gilroyMedium"
              fontSize={[14, 14, 16]}
              data-testid="orderName"
              className={classes.truncateText}
            >
              {JSON.parse(order.testSummary)
                .map(
                  test => `${test.count}x ${test.product_name} ${test.product_option_name || ""}`
                )
                .join(", ")}
            </Box>
          </Group>
        )}
      </Stack>
      <OrderStatusBadges order={order} />
    </Box>
  );
}
