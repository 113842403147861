import React from "react";

import useDocTitle from "@/hooks/use-doc-title";

import IFramePage from "./IFramePage";

function RegenerusTermsAndConditionsPage() {
  useDocTitle("Terms and conditions");
  return (
    <IFramePage
      withLogo={false}
      bg="partners"
      title="Terms And Conditions"
      src="/regenerus-terms-and-conditions.html"
    />
  );
}

export default RegenerusTermsAndConditionsPage;
