import React from "react";

import useDocTitle from "@/hooks/use-doc-title";

import IFramePage from "./IFramePage";

function RegenerusPrivacyPolicyPage() {
  useDocTitle("Privacy Policy");
  return (
    <IFramePage
      withLogo={false}
      bg="partners"
      title="Privacy Policy"
      src="/regenerus-privacy-policy.html"
    />
  );
}

export default RegenerusPrivacyPolicyPage;
