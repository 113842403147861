import React from "react";

import useDocTitle from "@/hooks/use-doc-title";

import IFramePage from "./IFramePage";

function RegenerusTermsOfUsePage() {
  useDocTitle("Terms of use");
  return (
    <IFramePage
      withLogo={false}
      bg="partners"
      title="Terms of use"
      src="/regenerus-terms-of-use.html"
    />
  );
}

export default RegenerusTermsOfUsePage;
